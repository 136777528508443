exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazinelibrary-js": () => import("./../../../src/pages/magazinelibrary.js" /* webpackChunkName: "component---src-pages-magazinelibrary-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-institution-template-js": () => import("./../../../src/templates/InstitutionTemplate.js" /* webpackChunkName: "component---src-templates-institution-template-js" */)
}

